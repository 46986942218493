import { ABIAppBar, LightOrDark } from "@ab-inbev-labs/ux-react-components";
import { FC, useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { UserProfile } from "./UserProfile";
import { HelpMenu } from "./HelpMenu";
import { LogoutButton } from "./LogoutButton";
import CollapsibleBreadcrumb from "./CollapsibleBreadcrumb";
import { AdditionalAppBarSettingsContent } from "./userProfileSettings/types";
import { useTranslation } from "react-i18next";
import { useIsOnline } from "../hooks/useIsOnline";

type AppBarWithBreadcrumbProps = {
  appTitle: string;
  isThisRenderedInvisibleOnWebpagePrint: boolean;
  showUserProfile?: boolean;
  additionalAppBarSettings?: AdditionalAppBarSettingsContent;
};

const AppBarWithBreadcrumb: FC<AppBarWithBreadcrumbProps> = ({
  appTitle,
  isThisRenderedInvisibleOnWebpagePrint,
  showUserProfile = true,
  additionalAppBarSettings,
}) => {
  const { doesCurrentApplicationUseSidebar } = useContext(SidebarContext);
  const i18next = useTranslation();
  const isOnline = useIsOnline();
  const showIsOffline =
    !isOnline && additionalAppBarSettings?.showNetworkStatus;

  const classNames =
    "sam-header " +
    `${doesCurrentApplicationUseSidebar ? "sam-header-with-sidebar" : ""} ` +
    `${isThisRenderedInvisibleOnWebpagePrint ? "no-print" : ""} ` +
    `${showIsOffline ? "app-is-offline" : ""}`;

  return (
    <ABIAppBar
      appTitle={appTitle}
      theme={LightOrDark.Light}
      breadcrumb={
        <CollapsibleBreadcrumb isThisRenderedInvisibleOnWebpagePrint />
      }
      className={classNames}
    >
      <div className="actions-and-offline-indicator">
        {
          <div className="offline-indicator">
            {showIsOffline ? i18next.t("common.labels.offline") : ""}
          </div>
        }
        <div className="user-profile-actions">
          <HelpMenu />

          {showUserProfile && (
            <UserProfile additionalSettings={additionalAppBarSettings} />
          )}

          {showUserProfile && <LogoutButton />}
        </div>
      </div>
    </ABIAppBar>
  );
};

export default AppBarWithBreadcrumb;
