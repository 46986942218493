import {
  ABIContent,
  ABIFooter,
  ABITopLoadingBar,
  LightOrDark,
} from "@ab-inbev-labs/ux-react-components";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FallbackRouteHandler from "./FallbackRouteHandler";
import AppBarWithBreadcrumb from "./components/AppBarWithBreadcrumb";
import MessageWithCloseButton from "./components/MessageWithCloseButtonModal";
import { MutationErrorContext } from "./context/MutationErrorContext";
import { SidebarContext } from "./context/SidebarContext";
import "./index.scss";
import { AdditionalAppBarSettingsContent } from "./components/userProfileSettings/types";
import { ComponentTestIds } from "./components/componentTestIds";

type LayoutProps = {
  appTitle: string;
  isGlobalLoadingDisplayed?: boolean;
  isThisRenderedInvisibleOnWebpagePrint?: boolean;
  contentClassName?: string;
  showUserProfile?: boolean;
  additionalAppBarSettings?: AdditionalAppBarSettingsContent;
};

const useGlobalLoadingAfterDelay = (
  isGlobalLoadingDisplayed: boolean,
): boolean => {
  // The delay prevents an excessively-flickering loading bar
  const GLOBAL_LOADING_DELAY_IN_MILLISECONDS = 100;
  const [isLoadingActive, setIsLoadingActive] = useState(false);

  const markGlobalLoadingAsActiveAfterDelay = (): (() => void) => {
    const delayTimer = setTimeout(
      () => setIsLoadingActive(true),
      GLOBAL_LOADING_DELAY_IN_MILLISECONDS,
    );
    return () => clearTimeout(delayTimer);
  };

  useEffect(() => {
    if (isGlobalLoadingDisplayed) return markGlobalLoadingAsActiveAfterDelay();
    else setIsLoadingActive(false);
  }, [isGlobalLoadingDisplayed]);

  return isLoadingActive;
};

const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  appTitle,
  isGlobalLoadingDisplayed = false,
  isThisRenderedInvisibleOnWebpagePrint = true,
  contentClassName,
  showUserProfile = true,
  additionalAppBarSettings,
}) => {
  const { t } = useTranslation();
  const [isMutationErrorModalShown, setIsMutationErrorModalShown] =
    useState(false);
  const [mutationErrorMessage, setMutationErrorMessage] = useState("");
  const [
    doesCurrentApplicationUseSidebar,
    setDoesCurrentApplicationUseSidebar,
  ] = useState(false);
  const isGlobalLoadingActive = useGlobalLoadingAfterDelay(
    isGlobalLoadingDisplayed,
  );

  return (
    <main>
      <MutationErrorContext.Provider
        value={{
          isMutationErrorModalShown,
          setIsMutationErrorModalShown,
          mutationErrorMessage,
          setMutationErrorMessage,
        }}
      >
        <SidebarContext.Provider
          value={{
            doesCurrentApplicationUseSidebar,
            setDoesCurrentApplicationUseSidebar,
          }}
        >
          <FallbackRouteHandler>
            {isGlobalLoadingActive && (
              <ABITopLoadingBar
                height="4px"
                inDeterminate
                data-testid={ComponentTestIds.GlobalLoadingIndicator}
              />
            )}
            <AppBarWithBreadcrumb
              appTitle={appTitle}
              isThisRenderedInvisibleOnWebpagePrint={
                isThisRenderedInvisibleOnWebpagePrint
              }
              showUserProfile={showUserProfile}
              additionalAppBarSettings={additionalAppBarSettings}
            />
            <ABIContent
              id="sidebar-portal"
              className={contentClassName}
              fullWidth
            >
              <div
                className={`sam-content ${
                  doesCurrentApplicationUseSidebar
                    ? "sam-content-with-sidebar"
                    : ""
                }`}
              >
                {children}
              </div>
              <MessageWithCloseButton
                i18KeyForTitle={"unexpectedNetworkError"}
                i18KeyForMessage={"recoverableErrorMessage"}
                jsonBlob={mutationErrorMessage}
                show={isMutationErrorModalShown}
                onClose={() => {
                  setIsMutationErrorModalShown(false);
                  setMutationErrorMessage("");
                }}
              />
            </ABIContent>
            <ABIFooter
              data-testid={ComponentTestIds.AbiFooter}
              theme={LightOrDark.Light}
              className={`sam-footer ${
                isThisRenderedInvisibleOnWebpagePrint ? "no-print" : ""
              } `}
            >
              <div>
                © {new Date().getFullYear()} {t("copyrightInfo")}
              </div>
            </ABIFooter>
          </FallbackRouteHandler>
        </SidebarContext.Provider>
      </MutationErrorContext.Provider>
    </main>
  );
};

export default Layout;
